.Brief {
    @apply bg-[#f1f0f3];
}
.BriefWrap {
    @apply grid py-20 gap-10 items-center;
    @apply xl:grid-cols-2;
}
.ContentWrap {
    @apply flex flex-col justify-center;
    @apply px-8;
}
.BriefHead {
    @apply max-w-lg font-extrabold;
}
.BriefContent {
    @apply text-medium;
}
.BriefImg,
.BriefImg img {
    @apply sm:rounded-2xl;
}
