.Section {
    @apply bg-[#ffffff] py-16;
}
.Wrap {
    @apply max-w-7xl mx-auto px-8 items-center;
}
.Grid {
    @apply grid;
    @apply xl:grid-cols-2 xl:gap-16;
}
.Content {
    @apply order-2;
    @apply xl:order-1;
}
.Video {
    @apply flex flex-col justify-center order-1 pb-6;
    @apply lg:items-center;
    @apply xl:order-2 xl:items-end xl:pb-0;
}
.videoWithRadius {
    @apply rounded-2xl;
}
