.PaginationWrap {
    @apply flex justify-center pt-10 pb-20 list-none mx-2 flex-nowrap h-fit max-w-fit relative gap-1 items-center overflow-visible rounded-md;
}

.NavItem,
.Prev,
.Next {
    @apply hover:cursor-pointer hover:bg-black hover:text-white;
    @apply transition-transform;
    @apply w-8 h-8;
    @apply flex flex-wrap items-center justify-center;
    @apply border border-black;
}
.Prev.Disabled,
.Next.Disabled {
    @apply text-gray-500 !important;
    @apply hover:cursor-default hover:bg-transparent !important;
}

.ActiveItem {
    @apply font-medium bg-black text-white;
}
