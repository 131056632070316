.hero {
    /*background: #f1f0f3;*/
    background-image: linear-gradient(105deg, #e5e5e5 0%, #ffffff 50%, #afafaf 100%);
}
.hero .heroWrap {
    @apply grid grid-cols-1 py-16;
    @apply md:grid-cols-2 md:gap-y-0 md:gap-x-10;
    @apply md:xl:gap-y-20;
}
.hero .heroHead {
    @apply flex flex-col justify-center sm:items-start px-8 md:px-0;
}
.hero .heroPic {
    @apply flex justify-center md:justify-end;
}
.hero .heroPic img {
    @apply max-h-[400px];
}

.heroIconList {
    @apply hidden xl:flex justify-center gap-8;
}
.heroIconCard {
    @apply flex justify-start gap-4;
}
.heroIconCard img {
    @apply w-[50px] h-[50px];
}
.heroLink {
    /*@apply mt-6 border-2 border-gray-900 text-gray-900 hover:bg-black hover:text-white px-4 py-2 rounded transition duration-300 font-bold uppercase;*/
    @apply mt-6 border-2 border-black bg-black text-white hover:bg-white hover:text-black px-4 py-2 rounded transition duration-300 font-bold uppercase;
}
