.choose {
    @apply py-16;
}
.HeadWrap {
    @apply flex flex-col items-center pb-10 px-8;
}
.BoxList {
    @apply grid xl:grid-cols-4 xl:gap-10;
}
.BoxList {
    @apply grid grid-cols-1 gap-10;
    @apply sm:grid-cols-2 sm:gap-6;
    @apply md:gap-10;
    @apply lg:grid-cols-3;
    @apply xl:grid-cols-4 xl:gap-10;
}
.BoxCard {
}
.CardTitleText {
    @apply text-lg text-center capitalize font-bold;
}

.CardImg .skeletonWrap {
    @apply md:rounded-large overflow-hidden;
}
.CardImg img {
    transition: transform 0.2s ease-in-out;
}
.CardImg:hover img {
    transform: scale(1.15); /* 根据需要调整放大的比例 */
}
