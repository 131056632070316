.Finishes {
    @apply py-20 bg-[#f8fafc];
}
.HeadWrap {
    @apply mb-16;
}
.Head,
.subHead {
    @apply text-center;
}
.CardList {
    @apply grid grid-cols-2 gap-4;
    @apply md:gap-8;
    @apply lg:grid-cols-3;
    @apply xl:grid-cols-4;
}
.CardItem {
    @apply bg-[#ffffff] rounded-xl p-3 text-gray-700 shadow-md;
}
.CardImg img {
    @apply rounded-xl;
}
.CardContent {
    @apply px-4 text-center pb-6;
}
.CardTitle {
    @apply text-xl;
}
