.Section {
    @apply bg-[#ffffff] py-16;
}
.Wrap {
    @apply max-w-7xl mx-auto px-8 items-center;
}
.Grid {
    @apply grid;
    @apply xl:grid-cols-2 xl:gap-16;
}
.CardItem {
    @apply relative flex flex-col justify-center order-1 pb-6;
    @apply xl:order-2 xl:pb-0;
}
.CardItem img {
    @apply xl:w-full;
}
.CardItem .skeletonWrap {
    @apply rounded-xl overflow-hidden;
    @apply md:rounded-large;
}
.Content {
    @apply order-2;
    @apply xl:order-1;
}
.videoWithRadius {
    @apply rounded-2xl;
}
